<template>
  <div class="card" style="border: none" id="print-lab-report">
    <div class="card-body">
      <!-- HEADER::START -->
      <!-- <div>
        <img
          :src="config.brand_logo"
          style="max-width: 200px; position: absolute; top: 6%; left: 11px"
          alt="LOGO KLINIK"
        />
      </div> -->
      <div class="row justify-content-center">
        <div class="col-6 text-center">
          <img
            :src="config.brand_logo"
            style="max-width: 250px"
            alt="LOGO KLINIK"
          />
          <h2>{{ config.clinic_name }}</h2>
          <p>{{ config.clinic_address }}</p>
        </div>
      </div>
      <hr style="border: 1px solid" class="mt-6" />
      <div class="d-flex justify-content-center">
        <h3>HASIL LABORATORIUM</h3>
      </div>
      <!-- HEADER::END -->
      <!-- CONTENT -->
      <div class="row mt-5">
        <div class="col-6">
          <table border="0">
            <tr>
              <td class="pr-6">Dokter</td>
              <td class="pr-2">:</td>
              <td>{{ data.reference }}</td>
            </tr>
            <tr>
              <td>Alamat</td>
              <td>:</td>
              <td>{{ data.reference_address }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-6">
          <table border="0">
            <tr>
              <td>ID Pasien</td>
              <td>:</td>
              <td>{{ data.patient_code }}</td>
            </tr>
            <tr>
              <td class="pr-3">Nama Pasien</td>
              <td class="pr-2">:</td>
              <td>{{ data.patient_name }}</td>
            </tr>
            <tr>
              <td>Alamat</td>
              <td>:</td>
              <td>{{ data.patient_address }}</td>
            </tr>
          </table>
        </div>
        <div class="col-6">
          <table border="0">
            <tr>
              <td>Jenis Kelamin</td>
              <td>:</td>
              <td>
                {{ data.patient_gender == "male" ? "Laki-laki" : "Perempuan" }}
              </td>
            </tr>
            <tr>
              <td class="pr-3">Tempat, Tanggal Lahir</td>
              <td class="pr-2">:</td>
              <td>
                {{ data.patient_birt_place != "" ? data.patient_birt_place + ", " + data.patient_birt_date_display : data.patient_birt_date_display }}
              </td>
            </tr>
            <tr>
              <td>Telepon</td>
              <td>:</td>
              <td>
                {{ data.patient_mobile_phone }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row mt-6">
        <div class="col">
          <p>{{ data.lab_template_opening_sentences }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <table class="table table-bordered">
            <tr>
              <th>No</th>
              <th>Jenis Pemeriksaan</th>
              <th>Hasil</th>
              <th>Nilai Normal</th>
              <th>Catatan</th>
            </tr>
            <template v-for="(item, i) in data.lab_results">
              <tr :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.field_show_name }}</td>
                <td>
                  <template v-if="item.result < item.start_range || item.result > item.end_range">
                   <span style="font-weight: bolder">{{item.result}}</span> <span class="text-danger">*</span>
                  </template>
                  <template v-else>
                    {{item.result}}
                  </template>
                </td>
                <td>
                  {{
                    item.start_range + " - " + item.end_range + " " + item.uom
                  }}
                </td>
                <td>{{ item.notes }}</td>
              </tr>
            </template>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p>{{ data.lab_template_closing_sentences }}</p>
        </div>
      </div>
      <hr>
      <div class="d-flex justify-content-start">
        <span>Waktu Pengambilan: {{data.display_date}}</span>
      </div>
      <div class="d-flex justify-content-between">
      <div class="d-flex flex-column mt-5 justify-content-center col-2 text-center px-0" style="min-height: 150px">
        <div>
          <span>Diotorisasi Oleh: </span>
        </div>
        <div class="mt-auto">
          <span class="d-block" style="white-space: nowrap;"><u>{{data.lab_template_authorizer_name}}</u></span>
          <span class="d-block">{{data.lab_template_authorizer_position}}</span>
          <!-- <hr class="mt-auto" style="border: 1px solid; width: 126px" /> -->
        </div>
      </div>
      <div class="d-flex justify-content-end mt-auto">
        <div class="col-5">
        <em>Tanda * dan angka dicetak tebal menunjukkan nilai diatas atau dibawah nilai normal</em>
        </div>
      </div>
      </div>
      <!-- <div class="d-flex justify-content-start mt-5">
        <span>Mengetahui</span>
      </div>
      <div class="d-flex justify-content-start mt-8">
        <hr>
      </div> -->
    </div>
  </div>
</template>

<script>
import ImageComponent from "@/component/general/ImageComponent.vue";
import { getConfiguration } from "@/core/services/jwt.service.js";

export default {
  components: {
    ImageComponent,
  },

  props: {
    data: Object,
    height: String,
    setting: Object,
  },

  data() {
    return {
      config: getConfiguration(),
    };
  },

  methods: {
    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print-lab-report");
    },
  },
};
</script>

<style>
figure.table table {
  width: 100% !important;
}
</style>